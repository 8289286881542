import React from "react";
import HomePage from "../HomePage/HomePage";

export default function Business() {
  return (
    <>
      <HomePage />
    </>
  );
}

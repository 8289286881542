import React, { useContext, useEffect, useRef, useState } from "react";
import { useUserAuth } from "../../context/UserAuthContext";
import "./HomePageOne.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { authentication } from "../../store/firebase-config";
import Context from "../../context/Context";

function LoggedUser({ setOpen, open }) {
  const { currentUser, setPreviousLocation } = useUserAuth();
  const { calendlyLink } = useContext(Context);
  const modalRef = useRef();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    setLoad(true);
  }, []);
  // useEffect(() => {
  //   const checkIfClickedOutside = (e) => {
  //     // If the modal is open and the clicked target is not within the modal,
  //     //then close the modal
  //     if (open && modalRef.current && !modalRef.current.contains(e.target)) {
  //       setOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", checkIfClickedOutside);

  //   // Cleanup the event listener
  //   return () => {
  //     document.removeEventListener("mousedown", checkIfClickedOutside);
  //   };
  // }, [open]);
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div
      className={`theLogBox transition duration-200 ease-in drop-shadow-md ${
        load ? "translate-y-0 opacity-100" : "-translate-y-5 opacity-0"
      }`}
      onClick={() => setOpen(false)}
      ref={modalRef}
      style={{
        backgroundColor: load ? "#fff" : "",
      }}
    >
      {/* {currentUser === null ? (
        <p
          onClick={() => {
            setPreviousLocation(window.location.pathname);
            navigate("/signup", {
              state: { previousLocation: location?.pathname },
            });
          }}
        >
          Sign in
        </p>
      ) : (
        <div>
          <div className="flex items-center justify-start gap-[10px]">
            <img
              className="w-[25px] object-cover rounded-full"
              src={currentUser?.photoURL}
              alt=""
            />
            <p className="text-[14px] text-[black] font-semibold">
              {currentUser?.displayName}
            </p>
          </div>
          <p
            onClick={() => {
              setPreviousLocation(window.location.pathname);
              authentication.signOut();
            }}
            className="mt-[10px]"
          >
            Log out
          </p>
        </div>
      )} */}
      {/* <Link
        to="/festival-campaigns"
        state={{ previousLocation: location?.pathname }}
      >
        <p className="mt-[10px] md:hidden block"> Festivity Campaigns</p>
      </Link>
      <Link
        to="/collaborations"
        state={{ previousLocation: location?.pathname }}
      >
        <p className="mt-[10px] md:hidden block"> For Sponsors</p>
      </Link>
      <Link
        to="/collab/influencer"
        state={{ previousLocation: location?.pathname }}
      >
        <p className="mt-[10px] md:hidden block">Collaborate</p>
      </Link> */}
      {/* <Link to="/pricing" state={{ previousLocation: location?.pathname }}>
        <p className="mt-[10px] md:hidden block">Pricing</p>
      </Link> */}
      {/* <Link
        to="/collab/sponser"
        state={{ previousLocation: location?.pathname }}
      >
        <p className="mt-[10px] md:hidden block"> Join as sponsor</p>
      </Link> */}
      {/* {calendlyLink && (
        <a href={calendlyLink} target="_blank">
          <p className="mt-[10px] md:hidden block"> Book a Demo</p>
        </a>
      )} */}
      {/* <Link to="/campaigns" state={{ previousLocation: location?.pathname }}>
        <p className="md:hidden block cursor-pointer text-[#4E04C6] mt-[10px]">
          Campaign Ideas
        </p>
      </Link> */}
      <Link to="/usecases" state={{ previousLocation: location?.pathname }}>
        <p className="mt-[10px]">Use Cases</p>
      </Link>
      <Link
        to="/#how-it-works"
        state={{ previousLocation: location?.pathname }}
      >
        <p className="mt-[10px]">How It Works</p>
      </Link>
      <Link to="/contact-us" state={{ previousLocation: location?.pathname }}>
        <p className="mt-[10px]">Contact Us</p>
      </Link>
    </div>
  );
}

export default LoggedUser;

import React, { useContext, useEffect, useState } from "react";
import CardOfSponser from "../../Components/InfluencerAndSponser/CardOfSponser";
import LoadingCircle from "../../Components/LoadingCircle/LoadingCircle";
import axios from "axios";
import styles from "./Collaboration.module.css";
import ReactPlayer from "react-player";
import Context from "../../context/Context";

function Collaboration() {
  const [campaignList, setCampaignList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generateLinkLoading, setGenerateLinkLoading] = useState(false);
  const [data, setdata] = useState({});
  const { buildType } = useContext(Context);

  useEffect(() => {
    // getCampaignList();
    getData();
    getCampaigns();
  }, []);
  // const getCampaignList = async () => {
  //   setLoading(true);
  //   try {
  //     const data = await axios.get(
  //       `${process.env.REACT_APP_GET_TRENDING_CAMPAIGNS}`
  //     );
  //     setCampaignList(data?.data?.data);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error.message);
  //   }
  // };
  const getData = async () => {
    try {
      const setApi = await axios.get(
        buildType == "highonevent"
          ? "https://storage.googleapis.com/goviral_home_data/influencers/jsons/infPageDataHoe.json"
          : "https://storage.googleapis.com/goviral_home_data/influencers/jsons/infPageData.json"
      );
      setdata(setApi.data);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const getCampaigns = async () => {
    try {
      setLoading(true);
      const setApi = await axios.get(
        buildType == "highonevent"
          ? "https://storage.googleapis.com/goviral_home_data/sponsors/jsons/campaignsHoe.json"
          : "https://storage.googleapis.com/goviral_home_data/sponsors/jsons/campaigns.json"
      );
      setCampaignList(setApi.data?.campaigns);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  return (
    <div>
      <div className={styles.campaigns}>
        <div className="max-w-[1280px] h-full w-full md:px-[78px] pt-[34px] md:pt-[60px] mx-auto">
          <h1 className="mb-[20px] px-[20px]">
            Campaigns looking for Sponsors
          </h1>
          <div className="flex w-full flex-col gap-[32px] overflow-auto hideScrollBar p-[20px]">
            {loading && (
              <div className="w-[100%] h-[30px] flex justify-center my-[20px] bg-white">
                <div className="w-[30px] h-[100%]">
                  <LoadingCircle />
                </div>
              </div>
            )}
            {campaignList?.map((obj, index) => {
              return (
                <CardOfSponser
                  data={obj}
                  generateLinkLoading={generateLinkLoading}
                  setGenerateLinkLoading={setGenerateLinkLoading}
                />
              );
            })}
          </div>
        </div>
      </div>
      {data?.videoUrl && (
        <div className="bg-[#d3f2fe66] py-[24px] lg:py-[70px]">
          <div className="max-w-[1280px] w-full px-[20px] lg:px-[78px] mx-auto">
            <div className={styles.videoSection}>
              <h2>
                It’s easy to make viral contests and giveaways in minutes.
              </h2>
              <p className="my-[20px] lg:mb-[46pxs ]">Watch a 5 min demo</p>
              <div className="w-full rounded-[24px] aspect-video">
                <ReactPlayer
                  config={{
                    file: {
                      attributes: { controlsList: "nodownload" },
                    },
                  }}
                  playing={false}
                  controls={true}
                  url={data?.videoUrl}
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Collaboration;

import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import "./HomePageOne.css";
import FB from "../../assests/fb.png";
import Insta from "../../assests/insta.png";
import Linkdin from "../../assests/link.png";
import Twitter from "../../assests/twit.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Context from "../../context/Context";

function Footer() {
  const navigate = useNavigate();
  const { buildType } = useContext(Context);
  const [footerData, setFooterData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getFooter();
  }, []);
  const getFooter = async () => {
    try {
      setLoading(true);
      const setApi = await axios.get(
        buildType == "highonevent"
          ? `https://storage.googleapis.com/goviral_home_data/homeMedia/jsons/hoeFooter.json`
          : `https://storage.googleapis.com/goviral_home_data/homeMedia/jsons/footer.json`
      );
      setFooterData(setApi.data);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  return (
    <>
      {!loading && (
        <div className="bg-white w-[100%] border-t-[1px] mt-[50px]">
          <div className="max-w-[1280px] mx-[auto] py-[50px] px-[20px]">
            <p className="goviralFooter">
              {buildType == "highonevent" ? "High on Event" : "Goviral"}
            </p>
            <div className="flex justify-between md:justify-around items-start flex-wrap flex-row mt-[24px]">
              <div className="flex justify-start items-start flex-col">
                <p className="goviralFooterTxt mb-[24px]">
                  {footerData?.tagline}
                </p>
                <p className="FooterTxt">{footerData?.description}</p>
                <div className="flex justify-center gap-[24px] mt-[60px] mb-[45px] items-center flex-row">
                  {footerData?.socialMedias?.instagram && (
                    <a
                      href={footerData?.socialMedias?.instagram}
                      target="_blank"
                    >
                      <img className="cursor-pointer" src={Insta} alt="" />
                    </a>
                  )}
                  {footerData?.socialMedias?.facebook && (
                    <a
                      href={footerData?.socialMedias?.facebook}
                      target="_blank"
                    >
                      <img className="cursor-pointer" src={FB} alt="" />
                    </a>
                  )}
                  {footerData?.socialMedias?.twitter && (
                    <a href={footerData?.socialMedias?.twitter} target="_blank">
                      <img className="cursor-pointer" src={Twitter} alt="" />
                    </a>
                  )}
                  {footerData?.socialMedias?.linkdIn && (
                    <a href={footerData?.socialMedias?.linkdIn} target="_blank">
                      <img className="cursor-pointer" src={Linkdin} alt="" />
                    </a>
                  )}
                </div>
              </div>
              <div>
                <div className="flex gap-[30px] flex-wrap justify-between ">
                  <div className="flex max-w-[173px] md:w-[100%] justify-start items-start flex-col">
                    <p className="text-[15px] font-semibold text-[black] mb-[24px]">
                      Our Services
                    </p>
                    {footerData?.OurServices?.map((obj, i) => {
                      return (
                        <p
                          onClick={() => navigate(obj?.urlNavigation)}
                          className="cursor-pointer text-[12px] md:text-[14px] mb-[16px] font-medium"
                          style={{ color: obj?.color }}
                        >
                          {obj?.name}
                        </p>
                      );
                    })}
                  </div>

                  <div className="flex w-[175px] md:w-fit md:justify-start items-start flex-col">
                    <p className="text-[15px] font-semibold text-[black] mb-[24px]">
                      Case Studies Examples
                    </p>
                    {footerData?.Case?.map((obj, i) => {
                      return (
                        <Link to={obj?.link}>
                          <p
                            className="cursor-pointer text-[12px] md:text-[14px] mb-[16px] font-medium"
                            style={{ color: obj?.color }}
                          >
                            {obj?.name}
                          </p>
                        </Link>
                      );
                    })}
                  </div>

                  <div className="flex max-w-[173px] md:w-[100%] justify-start items-start flex-col">
                    {/* <p className="text-[15px] font-semibold text-[black] mb-[24px]">
                  Comapany
                </p>
                {footerData?.Case?.map((obj, i) => {
                  return (
                    <p
                      className="cursor-pointer text-[12px] text-[14px] mb-[16px] font-medium"
                      style={{ color: obj?.color }}
                    >
                      {obj?.name}
                    </p>
                  );
                })} */}
                  </div>
                </div>
                <div className="">
                  <div className="flex justify-start items-start flex-col md:flex-row mt-[54px] ">
                    <div>
                      <p className="cursor-pointer text-[12px]text-[15px] font-semibold text-[black] ">
                        Connect with us
                      </p>
                      {/* <p className="cursor-pointer text-[12px]text-[12px] font-semibold text-[black] mt-[60px] ">
                © 2023 Goviral. All rights reserved.
              </p> */}
                    </div>
                    <div className="flex gap-[15px] items-start mt-[16px] md:mt-[0] flex-row justify-between flex-wrap">
                      <p className="text-[15px] md:ml-[180px] font-medium text-[#787878] ">
                        {footerData?.connect?.phone}
                      </p>
                      <p className="text-[15px]  md:ml-[165px] font-medium text-[#787878] ">
                        {footerData?.connect?.email}
                      </p>
                    </div>
                  </div>
                  <p className="cursor-pointer text-center md:text-left text-[12px]text-[12px] font-semibold text-[black] mt-[60px] ">
                    {buildType == "highonevent"
                      ? " © 2023 High on Event. All rights reserved."
                      : " © 2023 Goviral. All rights reserved."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;

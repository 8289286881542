import { createContext, useContext, useState } from "react";
import AccordianItem from "./AccordianItem";
import styles from "./Accordian.module.css";

const AccordionContext = createContext();

export function useAccordionContext() {
  const ctx = useContext(AccordionContext);

  if (!ctx) {
    throw new Error(
      "Accordion-related components must be wrapped by <Accordion>."
    );
  }

  return ctx;
}

export default function Accordion({ children }) {
  const [openItemId, setOpenItemId] = useState(0);

  function toggleItem(id) {
    setOpenItemId((prevId) => (prevId === id ? null : id));
  }

  const contextValue = {
    openItemId,
    toggleItem,
  };

  return (
    <AccordionContext.Provider value={contextValue}>
      <ul className={styles.accordian}>{children}</ul>
    </AccordionContext.Provider>
  );
}

Accordion.Item = AccordianItem;

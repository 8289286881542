import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { authentication } from "../store/firebase-config";
const Context = createContext();

export const ContextProvider = ({ children }) => {
  const buildType = process.env.REACT_APP_BUILD_TYPE;
  const [contestImageGalleryContents, setContestImageGalleryContents] =
    useState([]);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [clients, setClients] = useState();
  const [paymentFormDetails, setPaymentFormDetails] = useState({});
  const [paymentData, setpaymentData] = useState({});
  const ctstId = window.location.pathname.split("/")[3];
  const cmpId = window.location.pathname.split("/")[2];
  const [userList, setUserList] = useState();
  const [isHomePage, setIsHomePage] = useState(false);
  const [profiledata, setProfiledata] = useState("");
  const [singleContestDetails, setSingleContestDetails] = useState({});
  const [campaignId, setcampaignId] = useState(cmpId);
  const [contestId, setContestId] = useState(ctstId);
  const [contestData, setContestData] = useState();
  const [showCase, setShowCase] = useState([]);
  const [data, setData] = useState("");
  const [pinData, setPinData] = useState({});
  const [contestJson, setContestJson] = useState();
  const [terms, setTerms] = useState(false);
  const [contestRules, setContestRules] = useState(false);
  const [uploadSuccess, setUploadSucccess] = useState(false);
  const [campaignClosed, setCampaignClosed] = useState(false);
  const [campaignEntryClosed, setCampaignEntryClosed] = useState(false);
  const [uploadSuccessFile, setUploadSucccessFile] = useState({});
  const [stalls, setStalls] = useState("");
  const [sponsors, setSponsors] = useState("");
  const [homeData, setHomeData] = useState("");
  const [typeOfContest, setTypeOfContest] = useState("");
  const [top10Showcase, setTop10Showcase] = useState("");
  const [testimoniel, setTestimoniel] = useState("");
  const [influencerHomeData, setInfluencerHomeData] = useState();
  const [styles, setstyles] = useState("");
  const awsBaseURL =
    "https://s3.ap-south-1.amazonaws.com/goviral.world/pages/kalavathyteacher";
  const [isPrivate, setIsPrivate] = useState();
  const [campaignType, setCampaignType] = useState();
  //const organizerName = window.location.href.split('/')[2].split('.')[0]
  const [whatsUpLink, setWhatsUpLink] = useState(
    "https://wa.me/message/6LCH37NEKLYIE1"
  );
  const calendlyLink =
    buildType == "highonevent"
      ? ``
      : "https://calendly.com/goviral-support-team/goviral-product-demo";

  const [selectedContests, setSelectedContests] = useState({});
  const organizerName = window.location.href.split("/")[3];
  const contestName = window.location.pathname.split("/")[3];
  const campaignName = window.location.pathname.split("/")[2];

  const campaignMetaData = {
    organizerName,
    campaignName,
    contestName,
  };

  // const organizerName = 'kalavathyteacher'
  const url = window.location.href.split("/")[3];
  const eventCheker = (obj) => {
    setContestData(obj);
  };

  const [limit, setLimit] = useState(3);
  const [userIdToken, setUserIdToken] = useState();

  const top10Fuction = async (campaign) => {
    try {
      const setApi = await axios.get(
        `https://s3.ap-south-1.amazonaws.com/goviral.world/cpgn/garudtalkies/jayajayahey/showcase.json`
      );
      setTop10Showcase(setApi.data);
    } catch (err) {
      console.log(err.message);
      setTop10Showcase("");
    }
  };
  const ContestTypeFuction = async (org, campaign) => {
    try {
      const setApi = await axios.get(
        `https://storage.googleapis.com/goviral_campaign_data/${org}/${campaign}/homeData.json`
      );
      setTypeOfContest(setApi.data);
    } catch (err) {
      console.log(err.message);
      setTypeOfContest("");
    }
  };

  const homeFuction = async () => {
    try {
      const setApi = await axios.get(
        // `https://storage.googleapis.com/goviral_home_data/homeMedia/homeDatas/trending.json`
        `https://storage.googleapis.com/goviral_magazine_data/campaigns/data.json`
      );

      setHomeData(setApi.data);
    } catch (err) {
      console.log(err.message);
    }
  };
  const TestiMonialFuction = async () => {
    try {
      const setApi = await axios.get(
        `https://storage.googleapis.com/goviral_home_data/homeMedia/homeDatas/testimony.json`
      );
      setTestimoniel(setApi.data);
    } catch (err) {
      console.log(err.message);
    }
  };
  const verifyUser = async () => {
    try {
      const idToken = await authentication.currentUser.getIdToken(true);
      setUserIdToken(idToken);
      return idToken;
    } catch (error) {
      console.log("error", error);
    }
  };

  // contstData
  const profileDataFunction = async (name, languageSeeker) => {
    try {
      const setApi = await axios.get(
        `https://s3.ap-south-1.amazonaws.com/goviral.world/pages/kalavathyteacher/data_Eng.json`
      );
      setProfiledata(setApi.data);
    } catch (err) {
      console.log(err.message);
    }
  };
  //contestdetail
  const ContestDataFuction = async (name, languageSeeker) => {
    try {
      const setApi = await axios.get(
        `https://s3.ap-south-1.amazonaws.com/goviral.world/navadurga/contest/goviralcontestdata.json`
      );

      setContestJson(setApi.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const influAWSurl =
    "https://s3.ap-south-1.amazonaws.com/goviral.world/influencer";
  const influencerFuction = async (influencerId) => {
    try {
      const setApi = await axios.get(
        `https://us-central1-tripsmagic.cloudfunctions.net/goviralInfluencers/api/goviral/influencers/singleinfluencer/${influencerId}`
        // `https://s3.ap-south-1.amazonaws.com/goviral.world/influencer/${influencer}/data.json`
      );
      setInfluencerHomeData(setApi.data?.data);
    } catch (err) {
      console.log(err.message);
      setInfluencerHomeData("");
    }
  };

  const cssSwitcher = (color) => {
    if (influencerHomeData?.templeteColor === "white") {
      return setstyles(color);
    }
    if (influencerHomeData?.templeteColor === "black") {
      return setstyles(color);
    }
  };
  return (
    <Context.Provider
      value={{
        cssSwitcher,
        styles,
        setstyles,
        influencerHomeData,
        influencerFuction,
        paymentFormDetails,
        contestImageGalleryContents,
        setContestImageGalleryContents,
        setPaymentFormDetails,
        ContestDataFuction,
        contestJson,
        profileDataFunction,
        profiledata,
        awsBaseURL,
        contestData,
        setContestData,
        TestiMonialFuction,
        testimoniel,
        setcampaignId,
        showCase,
        userList,
        verifyUser,
        userIdToken,
        paymentData,
        setpaymentData,
        isHomePage,
        setIsHomePage,
        singleContestDetails,
        setSingleContestDetails,
        setContestId,
        contestId,
        eventCheker,
        data,
        transactionDetails,
        setTransactionDetails,
        setData,
        terms,
        contestRules,
        limit,
        setLimit,
        uploadSuccess,
        setUploadSucccess,
        campaignClosed,
        setShowCase,
        stalls,
        uploadSuccessFile,
        setUploadSucccessFile,
        sponsors,
        organizerName,
        setPinData,
        pinData,
        isPrivate,
        campaignType,
        homeFuction,
        homeData,
        ContestTypeFuction,
        typeOfContest,
        top10Fuction,
        top10Showcase,
        campaignMetaData,
        whatsUpLink,
        setWhatsUpLink,
        campaignEntryClosed,
        selectedContests,
        setSelectedContests,
        calendlyLink,
        buildType,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;

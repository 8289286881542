import React, { useContext, useEffect, useState } from "react";
import "./Case.css";
import Goviral from "../../assests/goviral.png";
import SugestedCampigns from "./SugestedCampigns";
import UserCount from "./UserCount";
import NavBar from "../HomeOfficial/Navbar";
import { useLocation, useNavigate, useParams } from "react-router";
import axios from "axios";
import { isEmpty } from "lodash";
import Context from "../../context/Context";
import BackHeader from "../BackHeader/BackHeader";
import { Link } from "react-router-dom";
function Case() {
  const { casestudy } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    getCaseStudy();
  }, []);
  const { calendlyLink, buildType } = useContext(Context);
  const [caseData, setCaseData] = useState();
  const getCaseStudy = async () => {
    try {
      const setApi = await axios.get(
        buildType == "highonevent"
          ? `https://storage.googleapis.com/goviral_home_data/homeMedia/caseStudies/hoe/${casestudy}.json`
          : `https://storage.googleapis.com/goviral_home_data/homeMedia/caseStudies/${casestudy}.json`
      );
      setCaseData(setApi.data);
    } catch (err) {
      console.log(err.message);
    }
  };
  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/usecases");
    }
  };
  return (
    <div className="bg-white w-[100%]">
      {/* <NavBar /> */}
      <BackHeader handleBack={handleNavigateToPrevLocation} heading="" />

      <div className="max-w-[1280px] mx-[auto] pt-[30px]">
        <div className="mx-[0] md:mx-[auto] max-w-[340px] pl-[80px] md:pl-[0px] md:max-w-[573px]">
          <p className="text-[18px] leading-[22px] md:text-[28px] clashFont text-black font-normal mt-[8px] text-left">
            Our case study on
          </p>
          <p className="clashFont mx-[auto] megaText ">{caseData?.heading}</p>
          <p className="mt-[12px] mb-[20px] text-[#787878] font-medium text-[14px] md:text-[14px] md:text-[17px] leading-[22px]">
            {caseData?.description}
          </p>
        </div>
        <div className="max-w-[300px] pl-[80px] md:pl-[0px] md:max-w-[573px] flex items-center justify-start md:justify-start gap-[16px] md:gap-[24px] mx-[auto] max-w-[100%] md:max-w-[573px]">
          {caseData?.products?.map((el) => (
            <p className="text-[14px] md:text-[17px] font-semibold text-[#4E04C6]">
              {el}
            </p>
          ))}
        </div>
        {caseData?.banner && (
          <img
            src={caseData?.banner}
            className=" mt-[20px] md:mt-[60px] w-[100%] h-[173px] md:h-[300px] object-contain "
            alt=""
          />
        )}
        <div className="mt-[60px] mx-[0] md:mx-[auto] max-w-[350px] md:max-w-[573px] pl-[80px] pr-[15px] md:pl-[0] md:pr-[0]">
          <p className="text-[21px] leading-[24px] md:text-[28px] clashFont text-black font-semibold mt-[8px] text-left leading-[32px]">
            Situation
          </p>
          <p className="mt-[12px] mb-[20px] text-[#787878] font-medium text-[14px] md:text-[17px] leading-[22px]">
            {caseData?.situation?.description}
          </p>
          <div className="cursor-pointer">
            {caseData?.situation?.situations?.map((obj, index) => {
              return (
                <div
                  className="flex items-start justify start gap-[15px] md:mb-[16px]"
                  key={index}
                >
                  <div className=" roundAbout">{index + 1}</div>
                  <div>
                    <p className=" font-semibold text-[15px] md:text-[17px] leading-[21px] inline text-[#4E04C6]">
                      {obj?.situation}
                    </p>
                    <p className=" mt-[12px]  text-[#787878] font-medium text-[12px] md:text-[17px] leading-[16px] md:leading-[22px]">
                      {obj?.description}
                    </p>
                  </div>
                </div>
              );
            })}
            <div className=" gridOfTesti">
              {caseData?.brandImages?.map((el, index) => {
                return (
                  <div key={index}>
                    <img
                      className="w-[126px] h-[126px] object-cover"
                      src={el}
                      alt=""
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <div className="mt-[60px]  max-w-[300px] md:max-w-[573px] px-[20px] md:px-[80px]">
            <p className="text-[21px] leading-[24px] md:text-[28px] clashFont text-black font-semibold mt-[8px] text-left leading-[32px]">
              Solution
            </p>
            <p className="mt-[12px] mb-[20px] text-[#787878] font-medium text-[14px] md:text-[17px] leading-[22px] max-w-[688px]">
              {caseData?.solution?.description}
            </p>
            {caseData?.solution?.solutions?.map((obj, index) => {
              return (
                <div
                  className="mt-[30px] md:mt-[50px] md:mb-[32px]"
                  key={index}
                >
                  <p className=" text-[#4E04C6] font-semibold text-[14px] md:text-[17px] leading-[20px]">
                    {obj?.solution}
                  </p>
                  <pre className="mt-[10px] font-inter text-[#787878] font-medium text-[14px] leading-[20px] max-w-[518px]">
                    {obj?.description}
                  </pre>
                </div>
              );
            })}
          </div>
          {!isEmpty(caseData?.suggestions) && (
            <SugestedCampigns data={caseData?.suggestions} />
          )}
          <div className="mx-[auto] pt-[60px] md:pt-[80px] px-[20px] md:px-[80px]">
            <p className="text-[18px] leading-[22px] md:text-[28px] leading-[32px] clashFont text-black font-semibold mt-[8px] text-left">
              Outcome
            </p>
            <p className="mt-[12px] mb-[20px] text-[#787878] font-medium text-[14px] md:text-[17px] leading-[22px] max-w-[688px]">
              {caseData?.outcome?.description}
            </p>
            {caseData?.outcome?.outcomes?.map((obj, index) => {
              return (
                <div
                  className="mt-[30px] md:mt-[50px] md:mb-[32px]"
                  key={index}
                >
                  <p className=" text-[#4E04C6] font-semibold text-[14px] md:text-[17px] leading-[20px]">
                    {obj?.outcome}
                  </p>
                  <pre className="mt-[10px] font-inter text-[#787878] font-medium text-[14px] leading-[20px] max-w-[518px]">
                    {obj?.description}
                  </pre>
                </div>
              );
            })}
          </div>
          {caseData?.outcome?.campaigns?.map((obj, index) => (
            <UserCount
              users={obj?.users}
              influencerCount={obj?.influencers}
              heading={obj?.heading}
              description={obj?.description}
              demoLink={obj?.demoLink}
              key={index}
            />
          ))}
          <div className="mx-[auto] pt-[80px] pb-[120px]  px-[20px] md:px-[80px]">
            <p className="text-[18px] leading-[22px] md:text-[28px] leading-[32px] clashFont text-black font-semibold mt-[8px] text-left">
              {caseData?.contact?.heading}
            </p>
            <p className="mt-[12px] mb-[20px] text-[#787878] font-medium text-[14px] md:text-[17px] leading-[22px] max-w-[688px]">
              {caseData?.contact?.description}
            </p>
            <div className="mt-[24px] flex items-start justify-start gap-[9px]">
              {/* <img
                src={Goviral}
                className="w-[28px] h-[28px] object-cover"
                alt=""
              /> */}
              <div>
                {/* <p className="text-[black] font-medium text-[14px] md:text-[17px] leading-[22px]">
                  Humanising Your Brand’s Experience
                </p> */}
                <Link
                  to="/contact-us"
                  state={{ previousLocation: location?.pathname }}
                >
                  <button className="text-[#4E04C6] font-medium text-[14px] md:text-[17px] leading-[22px]">
                    Connect with us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Case;

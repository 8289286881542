import React, { useContext, useEffect, useRef, useState } from "react";
import "./NewHomeGoviral.css";
import HereWeAre from "../../Components/NewHomeGoviral/HereWeAre";
import Brands from "../../Components/NewHomeGoviral/Brands";
import ContestSolutions from "../../Components/NewHomeGoviral/ContestSolutions";
import styles from "./NewHome.module.css";
import NumberDisplay from "../../Components/NewHomeGoviral/NumberDisplay";
import IdeasCard from "../../Components/NewHomeGoviral/IdeasCard";
import Accordian from "../../Components/Accordian/Accordian";
import AccordianItem from "../../Components/Accordian/AccordianItem";
import helpAvatar from "../../assests/helpAvatar.png";
import { Link, useLocation } from "react-router-dom";
import CardCarousel from "../../Components/CardCarousel/CardCarousel";
import axios from "axios";

function NewHomeGoviral() {
  const customerRef = useRef(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {
    getHomePageData();
  }, []);

  const getHomePageData = async () => {
    try {
      setLoading(true);
      const setApi = await axios.get(
        `https://storage.googleapis.com/goviral_home_data/homeMedia/jsons/newHomeData.json`
      );
      setData(setApi.data);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setData({});
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data?.howItWorksHeading) {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }
  }, [location, data]);

  useEffect(() => {
    if (data?.customers) {
      const scrollBounce = (ref, startFromRight = false) => {
        const element = ref.current;
        let isScrollingRight = !startFromRight;
        const scrollSpeed = 1; // Pixels per interval
        const intervalTime = 30; // Interval time in ms

        // If start from right, scroll to the far right initially
        if (startFromRight) {
          element.scrollLeft = element.scrollWidth;
        }

        const scroll = () => {
          element.scrollLeft += isScrollingRight ? scrollSpeed : -scrollSpeed;

          if (element.scrollLeft === 0) {
            isScrollingRight = true;
          } else if (
            element.scrollLeft + element.clientWidth >=
            element.scrollWidth
          ) {
            isScrollingRight = false;
          }
        };

        const interval = setInterval(scroll, intervalTime);

        return () => clearInterval(interval);
      };

      // Make the div scroll sideways
      const horizontalScroll = scrollBounce(customerRef, false);

      return () => {
        horizontalScroll();
      };
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <div className="h-[100vh]" />
      ) : (
        <div className={styles.homepage}>
          <div className={styles.landing}>
            <div className={styles.background} />
            <div className="max-w-[1280px] w-full h-full px-[20px] lg:px-[100px] pt-[90px] lg:pt-[128px] mx-auto flex flex-col items-center relative z-10">
              <h1 className=" md:w-[70%]">{data?.landing?.heading}</h1>
              {/* <h3 className="mt-[10px] w-[60%]">
            The ultimate platform to create fun, highly engaging contests
            tailored for any industry—apartments, IT companies, and beyond.
            Proven success: 100+ contests, 6L participants, and 5L+ brand
            engagements!
          </h3> */}
              <Link
                to="/contact-us"
                state={{ previousLocation: location?.pathname }}
              >
                <button className="mt-[30px]">
                  Post your Contest Requirments
                </button>
              </Link>
              <div className="flex items-start justify-between mt-[30px]">
                <div
                  className={`${styles.landingCard} rotate-[-8deg] mt-[-40px]`}
                >
                  <a href={data?.landing?.contest1?.url} target="_blank">
                    <img
                      src={data?.landing?.contest1?.coverImage}
                      className="w-full h-full object-cover"
                    />
                  </a>
                </div>
                <div className={`${styles.landingCard} !w-[15%]`}>
                  <a href={data?.landing?.contest2?.url} target="_blank">
                    <img
                      src={data?.landing?.contest2?.coverImage}
                      className="w-full h-full object-cover"
                    />
                  </a>
                </div>
                <div
                  className={`${styles.landingCard} rotate-[8deg] mt-[-40px]`}
                >
                  <a href={data?.landing?.contest3?.url} target="_blank">
                    <img
                      src={data?.landing?.contest3?.coverImage}
                      className="w-full h-full object-cover"
                    />
                  </a>
                </div>
              </div>
              <div className="absolute bottom-[10px] mx-auto w-fit flex flex-col items-center justify-center gap-[2px] z-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 5V17.25L17.25 12L18 12.66L11.5 19.16L5 12.66L5.75 12L11 17.25V5H12Z"
                    fill="white"
                  />
                </svg>
                <p>Swipe down to explore more</p>
              </div>
            </div>
          </div>
          <div className="bg-[#EEECFB]">
            <div className="py-[40px] md:[60px]">
              <div className="max-w-[1280px] px-[20px] lg:px-[100px] mx-auto ">
                <h2 className="w-[90%] md:w-[40%] text-[#000]">
                  {data?.facts?.heading}
                </h2>
                <h5 className="w-[80%] md:w-[40%] mt-[7px] md:mt-[16px]">
                  {data?.facts?.description}
                </h5>
              </div>
              <div className="flex lg:grid grid-cols-4 w-full gap-[24px] overflow-auto hideScrollBar mt-[30px] md:mt-[60px] max-w-[1280px] px-[20px] lg:px-[100px] mx-auto ">
                {data?.facts?.cards?.map((obj, index) => (
                  <div
                    className={styles.card}
                    key={index}
                    style={{ backgroundColor: obj?.bgColor }}
                  >
                    <div className="p-[22px]">
                      <h3 className="mb-[10px]">{obj?.heading}</h3>
                      <p>{obj?.description}</p>
                    </div>
                    <img src={obj?.graphics} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="bg-[#694AD6]">
            <div className="w-full h-full py-[40px] md:py-[60px]">
              <div className="max-w-[1280px] px-[20px] lg:px-[100px] mx-auto">
                <h2 className="text-[#FFF] w-[90%] md:w-[40%]">
                  {data?.ideas?.heading}
                </h2>
                <h5 className="text-[#FFFAED] w-[80%] md:w-[40%] mt-[7px] md:mt-[16px]">
                  {data?.ideas?.description}
                </h5>
              </div>
              <div className="mt-[29px] md:mt-[60px] max-w-[1280px] w-full mx-auto">
                <CardCarousel items={data?.ideas?.items} />
              </div>
            </div>
          </div>

          <div className="py-[40px] md:py-[60px]">
            <div className="max-w-[1280px] w-full h-full  mx-auto px-[20px] lg:px-[100px]">
              <h2 className="text-[#121212] w-full md:[w-50%]">
                {data?.customers?.heading}
              </h2>
            </div>
            <div
              className="flex gap-[20px] w-fit mx-auto items-center overflow-auto hideScrollBar mt-[29px] md:mt-[60px]"
              ref={customerRef}
            >
              {data?.customers?.items?.map((obj) => (
                <img
                  src={obj?.logo}
                  className="h-[60px] w-[158px] object-contain shrink-0"
                />
              ))}
            </div>
          </div>

          <div className={styles.howItWorks} id="how-it-works">
            <div className="max-w-[1280px] w-full h-full px-[20px] lg:px-[100px] mx-auto py-[40px] md:py-[60px]">
              <h2 className="w-full md:[w-50%]">{data?.howItWorksHeading} </h2>
              <h5 className="md:w-[40%] mt-[7px] md:mt-[16px]">
                {data?.howItWorksDesciption}
              </h5>
              <div className="mt-[40px] grid grid-cols-1 md:grid-cols-2">
                <div>
                  {data?.howItWorks?.map((obj) => (
                    <div className="flex gap-[20px]">
                      <div>
                        <div className={styles.dot} />
                        <div className={styles.line} />
                      </div>
                      <div className="mb-[30px]">
                        <h4 className=" mb-[18px]">{obj?.heading}</h4>
                        {obj?.description?.map((item) => (
                          <p className="max-w-[322px] mb-[12px]">{item}</p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  {data?.howItWorks2?.map((obj) => (
                    <div className="flex gap-[20px]">
                      <div>
                        <div className={styles.dot} />
                        <div className={styles.line} />
                      </div>
                      <div className="mb-[30px]">
                        <h4 className=" mb-[18px]">{obj?.heading}</h4>
                        {obj?.description?.map((item) => (
                          <p className="max-w-[322px] mb-[12px]">{item}</p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.perks}>
            <div className="max-w-[1280px] w-full h-full px-[20px] lg:px-[100px] mx-auto py-[40px] md:py-[60px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
                className="mb-[20px]"
              >
                <path
                  d="M20.1667 27.5H11L23.8333 1.83334V16.5H33L20.1667 42.1667V27.5Z"
                  fill="#2C2D2E"
                />
              </svg>
              <h2 className="w-[90%] md:w-[40%] text-[#4F52E6]">
                {data?.perks?.heading}
              </h2>
              <h5 className="w-[80%] md:w-[40%] mt-[7px] md:mt-[16px] mb-[40px]">
                {data?.perks?.description}
              </h5>
              <div className="rounded-[24px] bg-[#F2F1F1] px-[20px] md:px-[60px] py-[35px] md:py-[75px]  w-full">
                <div className="w-[90%] md:w-full grid grid-cols-1 md:grid-cols-2 justify-between gap-[60px] md:gap-[100px]">
                  <div>
                    <NumberDisplay number={data?.perks?.peopleJoined?.count} />
                    <h4 className="mt-[7px]">
                      {data?.perks?.peopleJoined?.heading}
                    </h4>
                  </div>
                  <div>
                    <h4>
                      <span> {data?.perks?.reach?.count} </span>{" "}
                      {data?.perks?.reach?.heading}
                    </h4>
                    <div></div>
                  </div>
                  <div>
                    <h4>
                      {data?.perks?.participation?.heading?.replace(
                        "[count]",
                        data?.perks?.participation?.count
                      )}
                    </h4>
                  </div>
                  <div>
                    <h4>
                      {data?.perks?.winners?.heading?.replace(
                        "[count]",
                        data?.perks?.winners?.count
                      )}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={styles.insight}>
        <div className="max-w-[1280px] w-full h-full px-[20px] lg:px-[100px] mx-auto py-[20px] grid grid-cols-2 md:grid-cols-3  gap-[20px]">
          <div className="hidden md:block">
            <h2 className="text-[#4F52E6] w-full md:[w-50%]">Our Insights</h2>
            <h5 className="text-[#000] mt-[7px] md:mt-[16px] md:w-[90%]">
              Create viral contests that captivate audiences, boost engagement,
              and drive quality leads—effortlessly.
            </h5>
          </div>
          <div className="col-span-2 grid grid-cols-2 gap-[20px] auto-rows-[150px]">
            <h2 className="text-[#4F52E6] w-full block md:hidden flex flex-col justify-center">
              Our Insights
            </h2>
            <div className="bg-[#4F52E6] rounded-[12px] p-[16px] flex flex-col justify-end md:gap-[6px]">
              <h3>400K+</h3>
              <p>Leads Generations</p>
            </div>
            <div className="bg-[#4F52E6] rounded-[12px] md:row-span-2 p-[16px] flex flex-col justify-end gap-[6px]">
              <h3>1,345 </h3>
              <p>Contests</p>
            </div>
            <div className="bg-[#4F52E6] rounded-[12px] md:row-span-2 p-[16px] flex flex-col justify-end gap-[6px]">
              <h3>1.7M+</h3>
              <p>Participations</p>
            </div>
            <div className="bg-[#4F52E6] rounded-[12px] p-[16px] flex flex-col justify-end gap-[6px] col-span-2 md:col-span-1">
              <h3>13,566 </h3>
              <p>Winners</p>
            </div>
          </div>
        </div>
      </div> */}
          <div className={styles.testimonial}>
            <div className="max-w-[1280px] w-full h-full px-[20px] lg:px-[100px] mx-auto py-[40px] md:py-[60px]">
              <h2 className="w-[90%] md:w-[40%] text-[#000]">
                {data?.testimonials?.heading}
              </h2>

              <div className="flex gap-[16px] overflow-auto hideScrollBar mt-[29px] md:mt-[50px] w-full">
                {data?.testimonials?.items?.map((obj) => (
                  <div className={styles.testimonialCard}>
                    <div className="flex items-center gap-[12px] mb-[20px]">
                      <img src={obj?.image} />
                      <div>
                        <p className={styles.testimonialName}>{obj?.name}</p>
                        <p className={styles.testimonialRole}>{obj?.role}</p>
                      </div>
                    </div>
                    <pre>{obj?.comment}</pre>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.qna}>
            <div className="max-w-[1280px] w-full h-full px-[20px] lg:px-[200px] mx-auto">
              <h2 className="text-[#D6CBFF] w-full md:[w-50%]">
                Frequently asked Questions.
              </h2>
            </div>
            <Accordian>
              {data?.faq?.map((obj, index) => (
                <Accordian.Item id={index} title={obj?.title}>
                  <pre>{obj?.description}</pre>
                </Accordian.Item>
              ))}
            </Accordian>
          </div>
          <div className={styles.help}>
            <Link
              to="/contact-us"
              state={{ previousLocation: window.location.pathname }}
            >
              <div className="max-w-[1280px] w-full h-full px-[20px] lg:px-[200px] mx-auto flex items-center justify-between gap-[12px]">
                <div className="flex items-center gap-[12px]">
                  <img src={helpAvatar} />
                  <p>Need more help? contact us</p>
                </div>
                <div className={styles.mailIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4 6L10.108 10.612L10.11 10.614C10.788 11.111 11.127 11.36 11.499 11.456C11.8276 11.541 12.1724 11.541 12.501 11.456C12.873 11.36 13.213 11.111 13.893 10.612C13.893 10.612 17.81 7.606 20 6M3 15.8L3 8.2C3 7.08 3 6.52 3.218 6.092C3.41 5.715 3.715 5.41 4.092 5.218C4.52 5 5.08 5 6.2 5L17.8 5C18.92 5 19.48 5 19.907 5.218C20.284 5.41 20.59 5.715 20.782 6.092C21 6.519 21 7.079 21 8.197V15.804C21 16.922 21 17.48 20.782 17.908C20.59 18.2845 20.2837 18.5904 19.907 18.782C19.48 19 18.921 19 17.803 19H6.197C5.079 19 4.519 19 4.092 18.782C3.71569 18.5903 3.40974 18.2843 3.218 17.908C3 17.48 3 16.92 3 15.8Z"
                      stroke="#FDFDFD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </Link>
          </div>
          {/* <HereWeAre />
      <Brands />
      <ContestSolutions /> */}
        </div>
      )}
    </>
  );
}

export default NewHomeGoviral;

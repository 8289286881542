import React, { useContext, useEffect, useState } from "react";
import styles from "./CampaignCategoryPage.module.css";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BackHeader from "../../Components/BackHeader/BackHeader";
import Context from "../../context/Context";

export default function CampaignCategoryPage() {
  const [data, setdata] = useState({});
  const { type } = useParams();
  const navigate = useNavigate();
  const { buildType } = useContext(Context);
  const location = useLocation();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const setApi = await axios.get(
        buildType == "highonevent"
          ? `https://storage.googleapis.com/goviral_home_data/campaignType/${type}/dataHoe.json`
          : `https://storage.googleapis.com/goviral_home_data/campaignType/${type}/data.json`
      );
      setdata(setApi.data);
    } catch (err) {
      console.log(err.message);
    }
  };
  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <div className="fixed top-[60px] sm:top-[84px] w-full">
        <BackHeader
          handleBack={handleNavigateToPrevLocation}
          heading=""
          background="transparent"
        />
      </div>
      <div
        className={styles.landing}
        style={{
          backgroundColor: data?.bgColor,
          backgroundImage: `url(${data?.graphics})`,
        }}
      >
        <div className="max-w-[1280px] h-[70vh]  w-full px-[20px] lg:px-[78px] mx-auto grid grid-cols-1 sm:grid-cols-2">
          <div className="h-full flex flex-col justify-center md:pb-[35px] pt-[54px]">
            <h1 className="mb-[12px] sm:mb-[16px]">{data?.heading}</h1>
            <p className="mb-[24px]">{data?.description}</p>
          </div>
          <div className="flex justify-end gap-[27px]">
            {/* <img src={data?.graphics} className="block md:hidden w-full" /> */}
          </div>
        </div>
      </div>
      <div>
        <div className="max-w-[1280px] h-full w-full px-[20px] lg:px-[78px] py-[20px] mx-auto flex flex-col items-center">
          <div className="grid grid-cols-1 lg:grid-cols-4 w-full gap-[40px] overflow-auto hideScrollBar ">
            {data?.contests?.map((obj) => (
              <Link
                to={`${obj?.uniqueText}`}
                state={{ previousLocation: location?.pathname }}
              >
                <div className={styles.card}>
                  {obj?.comingSoon && (
                    <div className={styles.comingSoon}>
                      <p>Coming soon...</p>
                    </div>
                  )}
                  <img src={obj?.coverImage} />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.footerBanner}>
        <div className="max-w-[1280px] w-full px-[20px] lg:px-[78px] mx-auto grid grid-cols-1 sm:grid-cols-2">
          <div className="h-full flex flex-col justify-center md:pb-[35px] pt-[54px]">
            <h1 className="mb-[12px] sm:mb-[16px]">
              {data?.footerBanner?.heading}
            </h1>
            <p className="mb-[24px]">{data?.footerBanner?.contact}</p>
            <div className="flex items-end gap-[16px]">
              {data?.footerBanner?.logos?.map((el) => (
                <img src={el} />
              ))}
            </div>
          </div>
          <div>
            <img src={data?.footerBanner?.graphics} className="" />
          </div>
        </div>
      </div>
    </>
  );
}

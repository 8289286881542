import React from "react";

function ContestSolutions() {

const contestSolData = [
  {
    id:1,
    head:"Boost Leads & Sales",
    text:"Run online contests to captivate and grow your audience."

  }
]


  return (
    <div className="mt-[60px] px-[20px]">
      <p className="Tailored text-[24px]">
        Tailored Contest Solutions for Every Need
      </p>

      <p className="tailorP text-[13px] mt-[16px]">
        Whether you're a brand aiming to boost sales or an event looking to
        captivate audiences, our platform offers end-to-end solutions to engage,
        promote, and deliver results seamlessly.
      </p>

      <div
        style={{
          background: "rgba(255, 210, 92, 0.11)",
        }}
        className="rounded-[16px]"
      >

        
      </div>
    </div>
  );
}

export default ContestSolutions;

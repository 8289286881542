import React from "react";
import styles from "./styles/NumberDIsplay.module.css";

const NumberDisplay = ({ number }) => {
  // Convert the number to a string, split into individual digits
  const digits = number?.toString()?.split("");

  return (
    <div className={styles.container}>
      {digits?.map((digit, index) => (
        <div key={index} className={styles.digit}>
          {digit}
        </div>
      ))}
    </div>
  );
};

export default NumberDisplay;

import styles from "./styles/ContactUsPage.module.css";
import signupBanner from "../assests/signupBanner.jpg";
import signupBannerMobile from "../assests/signupBannerMobile.jpg";
import { useContext, useEffect, useState } from "react";
import Context from "../context/Context";
import axios from "axios";
import BackHeader from "../Components/BackHeader/BackHeader";
import { useLocation, useNavigate } from "react-router";

export default function ContactUsPage() {
  const { calendlyLink, buildType } = useContext(Context);
  const [footerData, setFooterData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getFooter();
  }, []);
  const getFooter = async () => {
    try {
      const setApi = await axios.get(
        buildType == "highonevent"
          ? `https://storage.googleapis.com/goviral_home_data/homeMedia/jsons/hoeFooter.json`
          : `https://storage.googleapis.com/goviral_home_data/homeMedia/jsons/footer.json`
      );
      setFooterData(setApi.data);
    } catch (err) {
      console.log(err.message);
    }
  };
  const formImageRender = (innerWidth) => {
    if (innerWidth <= 640) {
      return signupBannerMobile;
    } else {
      return signupBanner;
    }
  };
  const divStyle = {
    backgroundImage: `url(${formImageRender(window.innerWidth)})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const handleNavigateToPrevLocation = () => {
    if (location?.state?.previousLocation) {
      navigate(location?.state?.previousLocation);
    } else {
      navigate("/");
    }
  };
  return (
    <div className="w-[100%] bg-white">
      <BackHeader handleBack={handleNavigateToPrevLocation} heading="" />

      <div className="w-[100%] h-[100%]">
        <div className="w-[100%] h-auto px-[20px] md:px-[15%]">
          <div className={styles.container}>
            <div className={styles?.image} style={divStyle}>
              <p>{buildType == "highonevent" ? "High On Event" : "Goviral"}</p>
            </div>
            <div className={styles?.content}>
              <div>
                <p className={styles.heading1}>Get in Touch</p>
                <p className={styles.desciption1}>
                  Connect with us effortlessly for inquiries, partnerships and
                  more
                </p>
              </div>
              <div>
                <div className="flex gap-[7px]">
                  <p className={styles.heading2}>Email:</p>
                  <p className={styles.desciption1}>
                    {footerData?.connect?.email}
                  </p>
                </div>
                {footerData?.connect?.phone && (
                  <div className="flex gap-[7px] ">
                    <p className={styles.heading2}>Phone:</p>
                    <p className={styles.desciption1}>
                      {footerData?.connect?.phone}
                    </p>
                  </div>
                )}
              </div>
              <div>
                {calendlyLink && (
                  <a href={calendlyLink} target="_blank">
                    <button>Request a demo</button>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="recaptcha-container"></div>
    </div>
  );
}

import React from "react";
import { useAccordionContext } from "./Accordian";
import styles from "./Accordian.module.css";

export default function AccordianItem({ id, title, children }) {
  const { openItemId, toggleItem } = useAccordionContext();

  const isOpen = openItemId === id;
  const icon = isOpen ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M16.212 7.05017L12.6765 10.5857L9.14098 7.05017C8.95344 6.86264 8.69909 6.75728 8.43387 6.75728C8.16866 6.75728 7.9143 6.86264 7.72677 7.05017C7.53923 7.23771 7.43387 7.49206 7.43387 7.75728C7.43387 8.02249 7.53923 8.27685 7.72677 8.46439L11.2623 11.9999L7.72677 15.5355C7.53923 15.723 7.43387 15.9773 7.43387 16.2426C7.43387 16.5078 7.53923 16.7621 7.72677 16.9497C7.9143 17.1372 8.16866 17.2426 8.43387 17.2426C8.69909 17.2426 8.95344 17.1372 9.14098 16.9497L12.6765 13.4141L16.212 16.9497C16.3996 17.1372 16.6539 17.2426 16.9192 17.2426C17.1844 17.2426 17.4387 17.1372 17.6263 16.9497C17.8138 16.7621 17.9192 16.5078 17.9192 16.2426C17.9192 15.9773 17.8138 15.723 17.6263 15.5355L14.0907 11.9999L17.6263 8.46439C17.8138 8.27685 17.9192 8.02249 17.9192 7.75728C17.9192 7.49206 17.8138 7.23771 17.6263 7.05017C17.4387 6.86264 17.1844 6.75728 16.9192 6.75728C16.6539 6.75728 16.3996 6.86264 16.212 7.05017Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M11.6765 6V11H6.67651C6.4113 11 6.15694 11.1054 5.96941 11.2929C5.78187 11.4804 5.67651 11.7348 5.67651 12C5.67651 12.2652 5.78187 12.5196 5.96941 12.7071C6.15694 12.8946 6.4113 13 6.67651 13H11.6765V18C11.6765 18.2652 11.7819 18.5196 11.9694 18.7071C12.1569 18.8946 12.4113 19 12.6765 19C12.9417 19 13.1961 18.8946 13.3836 18.7071C13.5712 18.5196 13.6765 18.2652 13.6765 18V13H18.6765C18.9417 13 19.1961 12.8946 19.3836 12.7071C19.5712 12.5196 19.6765 12.2652 19.6765 12C19.6765 11.7348 19.5712 11.4804 19.3836 11.2929C19.1961 11.1054 18.9417 11 18.6765 11H13.6765V6C13.6765 5.73478 13.5712 5.48043 13.3836 5.29289C13.1961 5.10536 12.9417 5 12.6765 5C12.4113 5 12.1569 5.10536 11.9694 5.29289C11.7819 5.48043 11.6765 5.73478 11.6765 6Z"
        fill="#FDFDFD"
      />
    </svg>
  );
  return (
    <li
      className={isOpen ? styles.openItem : ""}
      onClick={() => toggleItem(id)}
    >
      <div className="max-w-[1280px] w-full px-[20px] lg:px-[200px] mx-auto">
        <div className="flex items-center justify-between gap-[30px]">
          <h3>{title}</h3>
          <button>{icon}</button>
        </div>
        <div
          className={`transition duration-150 ease-out ${
            isOpen ? "block opacity-1" : "hidden opacity-0"
          }`}
        >
          {children}
        </div>
      </div>
    </li>
  );
}

import { ContextProvider } from "./context/Context";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import ProtecedRoute from "./ProtecedRoute";

import SignupPage from "./Pages/SignupPage";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { ViewContextProvider } from "./context/ViewContext";
import Error from "./Pages/Error";
import ContestDetailpage from "../src/Components/HostContestDetails/HostContestDetails";
//import NavBar from '../src/Components/NavBar/NavBar'
//import Nav from './HomeComponent/NavBar/Nav'
//import PaymentCheckout from './Components/PaymentCheckout/PaymentCheckout'
import PaymentSuccess from "./Components/PaymentSuccess/PaymentSuccess";
import PaymentFailed from "./Components/PaymentFailed/PaymentFailed";
import Home from "./Components/Home/Home";
import TypeOfContest from "./Components/TypeOfContest/TypeOfContest";
import ChatForHelp from "./Components/HelpButton/ChatForHelp";
import NewHome from "./Pages/NewHome";
import MatchPage from "./Pages/MatchPage";
import HomeForNew from "../src/Components/HomeOfficial/HomeForNew";
import OrgnizerPage from "./Components/FifaOrganizer/OrgnizerPage";
import HomeFooter from "./Components/Home/HomePageFooter/HomeFooter";
import Influencer from "./Components/InfluncerProfile/Influencer";
import InfluencerGallery from "./Components/InfluncerProfile/InfluencerGallery";
import InfluenceBio from "./Components/InfluncerProfile/InfluenceBio";
import MyCampigns from "./Components/InfluncerProfile/MyCampigns";
import InfulencerListing from "./Components/InfluncerProfile/InfulencerListing";
import InfluContact from "./Components/InfluncerProfile/InfluContact";
import HomePageOne from "./Components/HomeForNew2.0/HomePageOne";
import Case from "./Components/CaseStudy/Case";
import InfluencerCard from "./Components/InfluencerAndSponser/InfluencerCard";
import SponserCard from "./Components/InfluencerAndSponser/SponserCard";
import Collabrator from "./Components/CollabrtorPage/Collabrator";
import Costmetics from "./Components/HomeForNew2.0/Costmetics";
import CampaignPage from "./Pages/CampaignPage";
import CampaignCreatePage from "./Pages/CampaignCreatePage";
import CartPage from "./Pages/CartPage";
import CampaignCreationSuccessPage from "./Pages/CampaignCreationSuccessPage";
import SignupPageNew from "./Pages/SignupPageNew";
import UseCases from "./Pages/UseCases";
import Layout from "./Components/Layout";
import Data from "./Pages/data/Data";
import BrandPage from "./Pages/brandPage/BrandPage";
import InfluencerPage from "./Pages/influencerPage/InfluencerPage";
import Collaboration from "./Pages/collaboration/Collaboration";
import CaseStudyDetailPage from "./Pages/CaseStudyDetailPage";
import PricingPage from "./Pages/PricingPage";
import PortfolioListingPage from "./Pages/PortfolioListingPage";
import ContactUsPage from "./Pages/ContactUsPage";
import DemoCampaignsPage from "./Pages/DemoCampaignsPage";
import DemoCampaignsListingPage from "./Pages/DemoCampaignsListingPage";
import HomePage from "./Pages/HomePage/HomePage";
import QuotePage from "./Pages/QuotePage/QuotePage";
import BrandCollabPage from "./Pages/BrandCollabPage/BrandCollabPage";
import InfCollabPage from "./Pages/InfCollabPage/InfCollabPage";
import CampaignCategoryPage from "./Pages/CampaignCategoryPage/CampaignCategoryPage";
import CampaignDetailsPage from "./Pages/CampaignDetailsPage/CampaignDetailsPage";
import AffliationPage from "./Pages/AffliationPage/AffliationPage";
import Business from "./Pages/Business/Business";
import NewHomeGoviral from "./Pages/NewHome/NewHomeGoviral";

function App() {
  const organiserId = window?.location?.pathname?.split("/")[1];
  // const x = [1,2,3,4,5,6]
  // const y = [1,2,3,4,5,6]
  // x.sort((a,b)=>a- b)
  // y.sort((a,b)=>a- b)
  // let res = JSON.stringify(x) ===JSON.stringify(y)
  // console.log(res);
  return (
    <div className="App">
      <UserAuthContextProvider>
        <ContextProvider>
          <ViewContextProvider>
            {/* <ChatForHelp /> */}
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<NewHomeGoviral />} />
                <Route path="/business" element={<Business />} />
                <Route path="/quote" element={<QuotePage />} />
                <Route path="/signup" element={<SignupPageNew />} />
                <Route path="/:type" element={<CampaignCategoryPage />} />
                <Route
                  path="/:type/:campaign"
                  element={<CampaignDetailsPage />}
                />

                <Route path="/type/:domain" element={<Costmetics />} />
                {/* <Route path="/usecases/:domain" element={<Costmetics />} /> */}
                <Route path="/usecases" element={<UseCases />} />
                <Route
                  path="/usecases/:casestudy"
                  element={<CaseStudyDetailPage />}
                />
                <Route path="/brands" element={<BrandPage />} />
                <Route
                  path="/brands/post-requirement"
                  element={<BrandCollabPage />}
                />
                <Route path="/collab/influencer" element={<InfCollabPage />} />
                <Route
                  path="/collab/influencerOld"
                  element={<InfluencerCard />}
                />
                <Route
                  path="/collab/:orgUniqueText"
                  element={<Collabrator />}
                />
                <Route path="/collab/sponser" element={<SponserCard />} />

                <Route path="/Influencers" element={<PortfolioListingPage />} />
                <Route path="/collaborations" element={<Collaboration />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="/campaigns" element={<CampaignPage />} />
                <Route path="/campaigns/:category" element={<OrgnizerPage />} />
                <Route
                  path="/campaigns/create"
                  element={<CampaignCreatePage />}
                />
                <Route
                  path="/campaigns/create/success"
                  element={<CampaignCreationSuccessPage />}
                />
                <Route path="/campaigns/cart" element={<CartPage />} />
                <Route path="/campaigns/demo" element={<DemoCampaignsPage />} />
                <Route
                  path="/campaigns/demo/:category"
                  element={<DemoCampaignsListingPage />}
                />
              </Route>

              <Route path="/old" element={<HomeForNew />} />
              <Route path="/celebraties" element={<InfulencerListing />} />

              {/* <Route path="/:influencer" element={<Influencer />} />
              <Route
                path="/:influencer/gallery"
                element={<InfluencerGallery />}
              />
              <Route path="/:influencer/bio" element={<InfluenceBio />} />
              <Route path="/:influencer/contact" element={<InfluContact />} />
              <Route path="/:influencer/mycampigns" element={<MyCampigns />} /> */}

              {/* <Route path="/campaigns/:campaign" element={<NewHome />} /> */}
              {/* <Route
                  path="/campaigns/:category/create"
                  element={<MatchPage />}
                /> */}
              <Route path="/:org/:campaign" element={<TypeOfContest />} />
              <Route path="/payment" element={<ContestDetailpage />} />
              <Route path="/payment/success" element={<PaymentSuccess />} />
              <Route path="/payment/failed" element={<PaymentFailed />} />

              <Route path="/error" element={<Error />} />
              <Route path="/af" element={<AffliationPage />} />
            </Routes>
          </ViewContextProvider>
          {/* <HomeFooter/> */}
        </ContextProvider>
      </UserAuthContextProvider>
    </div>
  );
}

export default App;

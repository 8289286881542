import { useContext, useEffect, useState } from "react";
import { useUserAuth } from "../../context/UserAuthContext";
import styles from "./SignupBox.module.css";
import * as _ from "lodash";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import signupBanner from "../../assests/signupBanner.jpg";
import signupBannerMobile from "../../assests/signupBannerMobile.jpg";
import Context from "../../context/Context";

export default function SignupBox() {
  const {
    sendOtp,
    otpShow,
    numberVerificationLoading,
    otpVerificationLoading,
    otpResult,
    verifyOtp,
    resendOtp,
    otpError,
    googleSignIn,
    pageLoading,
  } = useUserAuth();
  const { buildType } = useContext(Context);
  const [number, setnumber] = useState();
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [numberObj, setNumberObj] = useState({
    countryCode: "+91",
    number: null,
  });
  const [timer, setTimer] = useState(59);
  const [zero, setZero] = useState("");
  const [otp, setOtp] = useState();

  const validatePhone = (code) => {
    let regexPhone =
      // eslint-disable-next-line no-useless-escape
      /^\+(?:[0-9]●?){6,14}[0-9]$/;

    if (code.match(regexPhone)) {
      setInvalidPhone(false);
      return true;
    } else {
      setInvalidPhone(true);
      return false;
    }
  };
  const handleSubmitOtp = (e) => {
    e.preventDefault();

    if (_.isEmpty(numberObj.countryCode)) {
      setInvalidPhone(true);
      return;
    }
    if (_.isEmpty(numberObj.number)) return;
    let temp = numberObj.countryCode + numberObj.number;
    temp = temp.replace(/\s+/g, "");

    const validateRespose = validatePhone(temp);
    if (!validateRespose) {
      return;
    }
    setnumber(`${temp}`);
    sendOtp(`${temp}`);
  };

  useEffect(() => {
    let myInterval = "  ";
    if (otpShow) {
      myInterval = setInterval(() => {
        timer > 0 && setTimer(timer - 1);
      }, 1000);
    }
    timer.toString().length === 1 && setZero(0);
    return () => {
      clearInterval(myInterval);
    };
  });
  const inputvalidation = (e) => {
    setOtp(e);
  };
  const formImageRender = (innerWidth) => {
    if (innerWidth <= 640) {
      return signupBannerMobile;
    } else {
      return signupBanner;
    }
  };
  const divStyle = {
    backgroundImage: `url(${formImageRender(window.innerWidth)})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <div className={styles.container}>
      <div className={styles?.image} style={divStyle}>
        <p>{buildType == "highonevent" ? "High On Event" : "Goviral"}</p>
      </div>
      <div className={styles?.content}>
        <div>
          <p className={styles.heading1}>
            Sign in to{" "}
            {buildType == "highonevent" ? "High On Event" : "Goviral"}
          </p>
          <p className={styles.desciption1}>
            Let’s start for unlimted Humanising Your Brand’s Experience{" "}
          </p>
        </div>
        {/* {!otpShow && (
          <form className={styles.form}>
            <label>Please enter Phone number </label>
            <div
              className={`${styles.phoneInput} ${
                invalidPhone ? styles.inputError : ""
              }`}
            >
              <input
                type="text"
                required
                onChange={(e) =>
                  setNumberObj({
                    ...numberObj,
                    countryCode: e.target.value,
                  })
                }
                value={numberObj?.countryCode}
              />
              <span className={styles.verticalLine} />
              <input
                type="text"
                required
                onChange={(e) =>
                  setNumberObj({
                    ...numberObj,
                    number: e.target.value,
                  })
                }
                value={numberObj?.number}
              />
            </div>
            {invalidPhone && (
              <p className={styles.error}>Invalid Phone number</p>
            )}
          </form>
        )}
        {otpShow && (
          <form className="flex flex-col justify-between basis-2/4 h-full w-full">
            <div>
              <label>Please enter the 6 digit code</label>
              <div
                className={`${styles.phoneInput} ${
                  otpError ? styles.inputError : ""
                }`}
              >
                <input
                  type="text"
                  required
                  onChange={(e) => inputvalidation(e.target.value)}
                  onInput={(e) => (e.target.value = e.target.value.slice(0, 6))}
                  className={styles.otpInput}
                />
              </div>
              {!otpError && (
                <p className={styles.numberSent}>
                  {" "}
                  We have send you a code to {number}
                </p>
              )}
              {otpError && <p className={styles.error}>Invalid OTP</p>}
            </div>

            <div className={styles.resendOtp}>
              <span>Didn't receive otp? </span>
              {timer == 0 ? (
                <span
                  className="hover:cursor-pointer text-[#3075FC]"
                  onClick={() => {
                    resendOtp(number);
                    setZero("");
                    setTimer(59);
                  }}
                >
                  Resend
                </span>
              ) : (
                <span className="ml-auto mr-auto">
                  Retry in 00:{zero}
                  {timer}
                </span>
              )}
            </div>
          </form>
        )}
        {!otpShow && (
          <button
            onClick={handleSubmitOtp}
            disabled={numberVerificationLoading}
          >
            {numberVerificationLoading ? <LoadingCircle /> : "Request OTP"}
          </button>
        )}
        {otpShow && (
          <button
            onClick={() => verifyOtp(otpResult, otp)}
            disabled={otpVerificationLoading}
          >
            {otpVerificationLoading ? <LoadingCircle /> : "Log in"}
          </button>
        )} */}
        <button onClick={googleSignIn} disabled={pageLoading}>
          {pageLoading ? <LoadingCircle /> : "Sign in with Google"}
        </button>
      </div>
    </div>
  );
}

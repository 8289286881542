import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import IdeasCard from "../NewHomeGoviral/IdeasCard";

const CarouselDot = ({ active, onClick, id }) => {
  const dotClass = `carousel-dot ${active ? "carousel-dot-active" : ""}`;
  return <div className={dotClass} onClick={onClick} id={id} />;
};

export default function CardCarousel({ items }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const cardRefs = useRef([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const handleScroll = () => {
      const sliderWidth = slider.offsetWidth;
      const sliderLeft = slider.getBoundingClientRect().left;
      const activeSlide = Array.from(slider.children).find((slide) => {
        const slideLeft = slide.getBoundingClientRect().left;
        return slideLeft >= sliderLeft;
      });
      setActiveIndex(
        activeSlide ? Array.from(slider.children).indexOf(activeSlide) : 0
      );
    };
    slider?.addEventListener("scroll", handleScroll);
    return () => slider?.removeEventListener("scroll", handleScroll);
  }, []);
  const handleNext = () => {
    let currentIndex = activeIndex;
    let nextIndex;
    if (currentIndex + 1 == items?.length) {
      nextIndex = currentIndex;
    } else {
      nextIndex = currentIndex + 1;
    }
    setActiveIndex(nextIndex);
    if (cardRefs.current[nextIndex]) {
      cardRefs.current[nextIndex].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };

  const handlePrevious = () => {
    let currentIndex = activeIndex;
    let prevIndex;
    if (currentIndex == 0) {
      prevIndex = currentIndex;
    } else {
      prevIndex = currentIndex - 1;
    }
    setActiveIndex(prevIndex);
    if (cardRefs.current[prevIndex]) {
      cardRefs.current[prevIndex].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };
  const dots = (items) =>
    items.map((_, index) => (
      <CarouselDot
        key={index}
        active={index === activeIndex}
        id={`dot-${index}`}
        // onClick={() => handleSlideChange(index)}
      />
    ));
  const isPrevDisabled = activeIndex === 0;
  const isNextDisabled = activeIndex === items?.length - 1;

  return (
    <div className="relative w-full">
      <div
        className="flex box gap-[16px] overflow-y-hidden overflow-x-auto hideScrollBar px-[20px] lg:px-[100px]"
        ref={sliderRef}
      >
        {items?.map((obj, index) => {
          return <IdeasCard data={obj} cardRefs={cardRefs} index={index} />;
        })}
      </div>
      {((window.innerWidth > 1024 && items?.length > 3) ||
        (window.innerWidth <= 1024 && items?.length > 1)) && (
        <>
          <div className="max-w-[1280px] mt-[30px] px-[20px] lg:px-[100px] mx-auto flex items-center justify-between">
            <button
              className="w-fit relative rotate-180 disabled:opacity-0"
              onClick={handlePrevious}
              disabled={isPrevDisabled}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="54"
                height="52"
                viewBox="0 0 54 52"
                fill="none"
                className="ml-auto"
              >
                <path
                  d="M53.5294 26.6309C53.5294 40.6418 38.711 52 24.9381 52C11.1652 52 0 40.6418 0 26.6309C0 12.6199 4.9338 1.91769e-07 18.7067 1.91769e-07C32.4796 1.91769e-07 53.5294 12.6199 53.5294 26.6309Z"
                  fill="white"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className="inset-0 absolute m-auto"
              >
                <path
                  d="M8.32002 2.08002L15.04 8.80002H0.400024V11.2H15.04L8.32002 17.92L10 19.6L19.6 10L10 0.400024L8.32002 2.08002Z"
                  fill="black"
                />
              </svg>
            </button>
            <div className="w-[100%] flex items-center justify-center gap-[6px] relative z-1 h-fit">
              {dots(items)}
            </div>
            <button
              className="w-fit relative disabled:opacity-0"
              onClick={handleNext}
              disabled={isNextDisabled}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="54"
                height="52"
                viewBox="0 0 54 52"
                fill="none"
                className="ml-auto"
              >
                <path
                  d="M53.5294 26.6309C53.5294 40.6418 38.711 52 24.9381 52C11.1652 52 0 40.6418 0 26.6309C0 12.6199 4.9338 1.91769e-07 18.7067 1.91769e-07C32.4796 1.91769e-07 53.5294 12.6199 53.5294 26.6309Z"
                  fill="white"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className="inset-0 absolute m-auto"
              >
                <path
                  d="M8.32002 2.08002L15.04 8.80002H0.400024V11.2H15.04L8.32002 17.92L10 19.6L19.6 10L10 0.400024L8.32002 2.08002Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
        </>
      )}
    </div>
  );
}

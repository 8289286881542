import React from "react";
import styles from "./styles/IdeasCard.module.css";
import { isEmpty } from "lodash";

export default function IdeasCard({ data, cardRefs, index }) {
  const randomColor = () => {
    const colors = [
      "#D301D0",
      "#4353FF",
      "#EB3F3A",
      "#3844E5",
      "#4A5CFF",
      "#3A4BE0",
      "#E63632",
      "#FF4B42",
      "#D8322F",
      "#C800C8",
      "#E402E4",
      "#AD01AC",
    ];
    const pickedColor = colors[Math.floor(Math.random() * colors?.length)];
    return pickedColor;
  };
  return (
    <div
      className={styles.card}
      ref={(element) => {
        cardRefs.current[index] = element;
      }}
    >
      <h3 className="mb-[12px]">{data?.heading}</h3>
      <p className="mb-[25px]">{data?.description}</p>
      {!isEmpty(data?.suitableFor) && (
        <div className={styles.suitableFor}>
          <p className="mb-[11px]">Best suitable for</p>
          {data?.suitableFor?.map((el, index) => {
            if (index < 4) {
              return (
                <h4
                  className="mb-[4px]"
                  style={{ color: randomColor() }}
                  key={el}
                >
                  {el}
                  {index == 3 && ".. etc"}
                </h4>
              );
            }
          })}
        </div>
      )}
      <div className="flex items-center w-full gap-[12px]">
        <img src={data?.resultIcon} />
        <h5>{data?.result}</h5>
      </div>
    </div>
  );
}
